import React from 'react';

import contactStyles from './contact.module.scss';

import instagram from '../assets/images/logo_insta.png';
import contact from '../assets/images/logo_mail.png';

export default class Contact extends React.Component {
    render() {
        return(
            <div className={contactStyles.container}>
              <a href="https://www.instagram.com/allegriagram/" target="_blank"><img src={instagram} alt="Instagram" className=""></img></a>
              <a href="mailto:delia@allegriaparis.com, mariebulle@allegriaparis.com" target="_blank" rel="noopener noreferrer"><img src={contact} alt="Contact" className=""></img></a>
            </div>
        );
    }
}